import React from "react"
import { StaticQuery, graphql } from "gatsby"
// import styled from "@emotion/styled"
import Tree from "./tree"
import styled from "styled-components"

const Sidebar = styled.aside`
    // width: 16%;
    height: 100vh;
    position: fixed;
    overflow-y: scroll !important;
    padding-left: 0%;
    padding-right: 1%;
    padding-top: 0%;
    // margin-bottom: 10%;
    background-color: var(--tw-white);
    color: var(--tw-black);
    box-shadow: 0 0 3px #555;
    width: 250px;
    // margin-bottom: 20em;
    

    & ul {
      margin: initial;
      padding-left: 2px;
      padding-right: 0px;
    }

    & li {
      list-style: none;
      padding-top: 4px;
    }

    & a {
      color: var(--tw-black);
      font-weight: 500;
      text-decoration: none;
      font-size: 11px;

      &:hover {
        color: var(--tw-blue);
        text-decoration: none;
      }

      // &:nth-child(2) {
      //   margin: px 0px; 
      //   border-bottom: 1px solid black;
      // }

      &:nth-child(2) {
        color: var(--tw-max);
        font-weight: 300;
        pointer-events: none;
        
      }
    }
`

const SidebarLayout = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        allMdx {
          edges {
            node {
              fields {
                slug
                title
              }
            }
          }
        }
      }
    `}
    render={({ allMdx }) => {
      return (
        //Link to External HBOMax image specs at end of sidebar. 
        //Probably a better way to this, but not sure how. It's good enough for now.
        <Sidebar>
          <ul className={"sideBarUL"}>
            <Tree edges={allMdx.edges} />
            <li className= " item " style={{flex:1 }}><a onClick={() => window.open("https://hbomaximagespecs.com/")} style={{paddingLeft: 12, fontWeight: '300'}} href="">Image Specifications and Guidelines</a></li>
          </ul>
        </Sidebar>
      )
    }}
  />
)

export default SidebarLayout
