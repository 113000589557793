import React from "react"
import styled from "@emotion/styled"
import AcceptedLangs from '../lang-tables/accepted-languages'
import AcceptedLangs2 from '../lang-tables/accepted-languages-2'
import NotAcceptedLangs from '../lang-tables/no-longer-accepted-languages'
import ExcludedLanguages from '../lang-tables/excluded-languages'

/* eslint-disable react/display-name */
export default {
  AcceptedLangs,
  AcceptedLangs2,
  NotAcceptedLangs,
  ExcludedLanguages,
  h1: props => (
    <h1 id={props.children.replace(/\s+/g, "").toLowerCase()} {...props}>
      {props.children}
    </h1>
  ),
  h2: props => (
    <h2 id={props.children.replace(/\s+/g, "").toLowerCase()} {...props}>
      {props.children}
    </h2>
  ),
  h3: props => (
    <h3 id={props.children.replace(/\s+/g, "").toLowerCase()} {...props}>
      {props.children}
    </h3>
  ),
  h4: props => (
    <h4 id={props.children.replace(/\s+/g, "").toLowerCase()} {...props}>
      {props.children}
    </h4>
  ),
  h5: props => (
    <h5 id={props.children.replace(/\s+/g, "").toLowerCase()} {...props}>
      {props.children}
    </h5>
  ),
  h6: props => (
    <h6 id={props.children.replace(/\s+/g, "").toLowerCase()} {...props}>
      {props.children}
    </h6>
  ),
  PrintButton: styled(({ className, ...props }) => (
    <a className={className} onClick={() => window.print()} title="Print">
      <span className="text-en">Print</span>
    </a>
  ))`
    position: absolute;
    top: 15px;
    right: 5%;
    display: block;
    border-radius: 5px;
    background-color: var(--tw-black);
    color: var(--tw-white );
    text-transform: uppercase;
    transition: all 0.2s ease 0s;
    padding: 5px 10px;
    font-size: 12px;

    &:hover {
      box-shadow: 0 0 2px #555;
      background-color: var(--tw-dark-gray);
      color: white
    }

  `,

  // Begin EmbeddedComponents

 DVSComponent: props => (
   <div>
    <h4>Descriptive Video/Audio Description</h4>
    <p>Warner Bros. Discovery requires the delivery of Descriptive Video / Audio Description tracks, where pre-existing, to be delivered in the following manner:</p>
      <p>
        <ol>
          <li>Descriptive Video/Audio Description must be mixed with primary stereo audio.</li>
          <li>Descriptive Video/Audio Description must conform to the primary program video.</li>
          <li>Descriptive Video/Audio Description must adhere to Master Technical Specifications.</li>
        </ol>
      </p>
   </div>
 ),



//  testComponent: props => (
//   <div>
//    <p>Test Language</p>
//   </div>
// ),

//  End EmbeddedComponents

}
