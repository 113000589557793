import React from "react"

export default () => (
<table >
<thead>
<tr>
<th scope="col">Description</th>
<th scope="col">Audio Language Tag</th>
<th scope="col">Visual Language Tag</th>
<th scope="col">Status</th>
</tr>
</thead>
<tbody>
<tr>
<td >
<p>Abkhazian</p>
</td>
<td >
<p>ab</p>
</td>
<td >
<p>ab</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Afrikaans</p>
</td>
<td >
<p>af</p>
</td>
<td >
<p>af</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Albanian</p>
</td>
<td >
<p>sq</p>
</td>
<td >
<p>sq</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Arapho</p>
</td>
<td >
<p>arp</p>
</td>
<td >
<p>arp</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Amharic</p>
</td>
<td >
<p>am</p>
</td>
<td >
<p>am</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Arabic</p>
</td>
<td >
<p>ar</p>
</td>
<td >
<p>ar</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Arabic as spoken in Algeria</p>
</td>
<td >
<p>arq</p>
</td>
<td >
<p>arq</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Arabic as spoken in Egypt</p>
</td>
<td >
<p>arz</p>
</td>
<td >
<p>arz</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Arabic as spoken in Lebanon</p>
</td>
<td >
<p>apc</p>
</td>
<td >
<p>apc</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Arabic as spoken in Morocco</p>
</td>
<td >
<p>ary</p>
</td>
<td >
<p>ary</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Arabic as spoken in Tunisia</p>
</td>
<td >
<p>aeb</p>
</td>
<td >
<p>aeb</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Standard Arabic</p>
</td>
<td >
<p>arb</p>
</td>
<td >
<p>arb</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Armenian</p>
</td>
<td >
<p>hy</p>
</td>
<td >
<p>hy</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Western Armenian</p>
</td>
<td >
<p>hyw</p>
</td>
<td >
<p>hyw</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Assamese</p>
</td>
<td >
<p>as</p>
</td>
<td >
<p>as</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Aymara</p>
</td>
<td >
<p>ay</p>
</td>
<td >
<p>ay</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Azerbaijani</p>
</td>
<td >
<p>az</p>
</td>
<td >
<p>az-Latn</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Bagri</p>
</td>
<td >
<p>raj</p>
</td>
<td >
<p>raj</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Bambara</p>
</td>
<td >
<p>bm</p>
</td>
<td >
<p>bm</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Bantu</p>
</td>
<td >
<p>bnt</p>
</td>
<td >
<p>bnt</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Basque</p>
</td>
<td >
<p>eu</p>
</td>
<td >
<p>eu</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Belarusian</p>
</td>
<td >
<p>be</p>
</td>
<td >
<p>be</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Bengali</p>
</td>
<td >
<p>bn</p>
</td>
<td >
<p>bn</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Berber</p>
</td>
<td >
<p>ber</p>
</td>
<td >
<p>ber</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Bhojpuri</p>
</td>
<td >
<p>bho</p>
</td>
<td >
<p>bho</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Bislama</p>
</td>
<td >
<p>bi</p>
</td>
<td >
<p>bi</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Bosnian</p>
</td>
<td >
<p>bs</p>
</td>
<td >
<p>bs</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Bulgarian</p>
</td>
<td >
<p>bg</p>
</td>
<td >
<p>bg</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Burmese</p>
</td>
<td >
<p>my</p>
</td>
<td >
<p>my</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Catalan</p>
</td>
<td >
<p>ca</p>
</td>
<td >
<p>ca</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Catalan as spoken in France</p>
</td>
<td >
<p>ca-FR</p>
</td>
<td >
<p>ca-FR</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Cherokee</p>
</td>
<td >
<p>chr</p>
</td>
<td >
<p>chr</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Cantonese</p>
</td>
<td >
<p>yue</p>
</td>
<td >
<p>&nbsp;</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Mandarin</p>
</td>
<td >
<p>cmn</p>
</td>
<td >
<p>&nbsp;</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Min Nan</p>
</td>
<td >
<p>nan</p>
</td>
<td >
<p>&nbsp;</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td colspan="2" >
<p>Simplified Chinese</p>
</td>
<td >
<p>zh-Hans</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td colspan="2" >
<p>Traditional Chinese</p>
</td>
<td >
<p>zh-Hant</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Corsican</p>
</td>
<td >
<p>co</p>
</td>
<td >
<p>co</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Czech</p>
</td>
<td >
<p>cs</p>
</td>
<td >
<p>cs</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Danish</p>
</td>
<td >
<p>da</p>
</td>
<td >
<p>da</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Divehi</p>
</td>
<td >
<p>dv</p>
</td>
<td >
<p>&nbsp;</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Dutch</p>
</td>
<td >
<p>nl</p>
</td>
<td >
<p>nl</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Dzongkha</p>
</td>
<td >
<p>dz</p>
</td>
<td >
<p>dz</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>English as spoken in Australia</p>
</td>
<td >
<p>en-AU</p>
</td>
<td >
<p>en-AU</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>English as spoken in Canada</p>
</td>
<td >
<p>en-CA</p>
</td>
<td >
<p>en-CA</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>English as spoken in Hong Kong</p>
</td>
<td >
<p>en-HK</p>
</td>
<td >
<p>en-HK</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>English as spoken in Malaysia</p>
</td>
<td >
<p>en-MY</p>
</td>
<td >
<p>en-MY</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>English as spoken in Montserrat</p>
</td>
<td >
<p>en-MS</p>
</td>
<td >
<p>en-MS</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>English as spoken in People's Republic of China</p>
</td>
<td >
<p>en-CN</p>
</td>
<td >
<p>en-CN</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>English as spoken in South Africa</p>
</td>
<td >
<p>en-ZA</p>
</td>
<td >
<p>en-ZA</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>English as spoken in the Philippines</p>
</td>
<td >
<p>en-PH</p>
</td>
<td >
<p>en-PH</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Esperanto</p>
</td>
<td >
<p>eo</p>
</td>
<td >
<p>eo</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Estonian, Standard</p>
</td>
<td >
<p>ekk</p>
</td>
<td >
<p>ekk</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Faroese</p>
</td>
<td >
<p>fo</p>
</td>
<td >
<p>fo</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Fijian</p>
</td>
<td >
<p>fj</p>
</td>
<td >
<p>fj</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Filipino</p>
</td>
<td >
<p>fil</p>
</td>
<td >
<p>fil</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Finnish</p>
</td>
<td >
<p>fi</p>
</td>
<td >
<p>fi</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>French as spoken in Belgium</p>
</td>
<td >
<p>fr-BE</p>
</td>
<td >
<p>fr-BE</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>French as spoken in Switzerland</p>
</td>
<td >
<p>fr-CH</p>
</td>
<td >
<p>fr-CH</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Frisian - Eastern</p>
</td>
<td >
<p>frs</p>
</td>
<td >
<p>frs</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Frisian - Northern</p>
</td>
<td >
<p>frr</p>
</td>
<td >
<p>frr</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Frisian - Western</p>
</td>
<td >
<p>fy</p>
</td>
<td >
<p>fy</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Gaelic as spoken in Scotland</p>
</td>
<td >
<p>gd</p>
</td>
<td >
<p>gd</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Galician</p>
</td>
<td >
<p>gl</p>
</td>
<td >
<p>gl</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Ganda</p>
</td>
<td >
<p>lg</p>
</td>
<td >
<p>lg</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Georgian</p>
</td>
<td >
<p>ka</p>
</td>
<td >
<p>ka</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Ancient Greek</p>
</td>
<td >
<p>grc</p>
</td>
<td >
<p>grc</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Greek as spoken in Cyprus</p>
</td>
<td >
<p>el-CY</p>
</td>
<td >
<p>el-CY</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Greek, Modern (1453-)</p>
</td>
<td >
<p>el</p>
</td>
<td >
<p>el</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Guarani</p>
</td>
<td >
<p>gn</p>
</td>
<td >
<p>gn</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Gujarati</p>
</td>
<td >
<p>gu</p>
</td>
<td >
<p>gu</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Hausa</p>
</td>
<td >
<p>ha</p>
</td>
<td >
<p>ha</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Hawaiian</p>
</td>
<td >
<p>haw</p>
</td>
<td >
<p>haw</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Hawaiian Pidgin</p>
</td>
<td >
<p>hwc</p>
</td>
<td >
<p>hwc</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Hebrew</p>
</td>
<td >
<p>he</p>
</td>
<td >
<p>he</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Hindi</p>
</td>
<td >
<p>hi</p>
</td>
<td >
<p>hi</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Hindi as spoken in Fiji</p>
</td>
<td >
<p>hif</p>
</td>
<td >
<p>hif</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Hungarian</p>
</td>
<td >
<p>hu</p>
</td>
<td >
<p>hu</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Icelandic</p>
</td>
<td >
<p>is</p>
</td>
<td >
<p>is</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Igbo</p>
</td>
<td >
<p>ig</p>
</td>
<td >
<p>ig</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Indonesian</p>
</td>
<td >
<p>id</p>
</td>
<td >
<p>id</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Inuktitut</p>
</td>
<td >
<p>iu</p>
</td>
<td >
<p>iu</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Irish</p>
</td>
<td >
<p>ga</p>
</td>
<td >
<p>ga</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Italian</p>
</td>
<td >
<p>it</p>
</td>
<td >
<p>it</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Neapolitan</p>
</td>
<td >
<p>nap</p>
</td>
<td >
<p>nap</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Jamaican Creole English</p>
</td>
<td >
<p>jam</p>
</td>
<td >
<p>jam</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Japanese</p>
</td>
<td >
<p>ja</p>
</td>
<td >
<p>ja</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Jauer dialect of Romansh</p>
</td>
<td >
<p>rm</p>
</td>
<td >
<p>rm</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Kalaallisut</p>
</td>
<td >
<p>kl</p>
</td>
<td >
<p>kl</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Kannada</p>
</td>
<td >
<p>kn</p>
</td>
<td >
<p>kn</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Kazakh</p>
</td>
<td >
<p>kk</p>
</td>
<td >
<p>kk</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Kinyarwanda</p>
</td>
<td >
<p>rw</p>
</td>
<td >
<p>rw</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Kirghiz</p>
</td>
<td >
<p>ky</p>
</td>
<td >
<p>ky-Cyrl</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Klingon</p>
</td>
<td >
<p>tlh</p>
</td>
<td >
<p>tlh</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Korean</p>
</td>
<td >
<p>ko</p>
</td>
<td >
<p>ko</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Kurdish</p>
</td>
<td >
<p>ku</p>
</td>
<td >
<p>ku</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Ladakhi</p>
</td>
<td >
<p>lbj</p>
</td>
<td >
<p>lbj</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Lao</p>
</td>
<td >
<p>lo</p>
</td>
<td >
<p>lo</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Latvian</p>
</td>
<td >
<p>lv</p>
</td>
<td >
<p>lv</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Lithuanian</p>
</td>
<td >
<p>lt</p>
</td>
<td >
<p>lt</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Luxembourgish</p>
</td>
<td >
<p>lb</p>
</td>
<td >
<p>lb</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Macedonian</p>
</td>
<td >
<p>mk</p>
</td>
<td >
<p>mk</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Malagasy</p>
</td>
<td >
<p>mg</p>
</td>
<td >
<p>mg</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Malay</p>
</td>
<td >
<p>ms</p>
</td>
<td >
<p>ms</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Malayalam</p>
</td>
<td >
<p>ml</p>
</td>
<td >
<p>ml</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Maltese</p>
</td>
<td >
<p>mt</p>
</td>
<td >
<p>mt</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Maori</p>
</td>
<td >
<p>mi</p>
</td>
<td >
<p>mi</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Marathi</p>
</td>
<td >
<p>mr</p>
</td>
<td >
<p>mr</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Mayan</p>
</td>
<td >
<p>myn</p>
</td>
<td >
<p>myn</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Khmer</p>
</td>
<td >
<p>km</p>
</td>
<td >
<p>km</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Mongolian</p>
</td>
<td >
<p>mn</p>
</td>
<td >
<p>mn-Cyrl</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Mossi</p>
</td>
<td >
<p>mos</p>
</td>
<td >
<p>mos</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Motu</p>
</td>
<td >
<p>meu</p>
</td>
<td >
<p>meu</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Nauru</p>
</td>
<td >
<p>na</p>
</td>
<td >
<p>na</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Navajo</p>
</td>
<td >
<p>nv</p>
</td>
<td >
<p>nv</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Nepali</p>
</td>
<td >
<p>ne</p>
</td>
<td >
<p>ne</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Northern Sotho</p>
</td>
<td >
<p>nso</p>
</td>
<td >
<p>nso</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Norwegian</p>
</td>
<td >
<p>no</p>
</td>
<td >
<p>no</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td colspan="2" >
<p>New Norwegian</p>
</td>
<td >
<p>nn</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td colspan="2" >
<p>Norwegian Bokm&aring;l</p>
</td>
<td >
<p>nb</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Nyanja</p>
</td>
<td >
<p>ny</p>
</td>
<td >
<p>ny</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Odia</p>
</td>
<td >
<p>or</p>
</td>
<td >
<p>or</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Palauan</p>
</td>
<td >
<p>pau</p>
</td>
<td >
<p>pau</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Pashto</p>
</td>
<td >
<p>ps</p>
</td>
<td >
<p>ps</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Persian</p>
</td>
<td >
<p>fa</p>
</td>
<td >
<p>fa</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Dari</p>
</td>
<td >
<p>prs</p>
</td>
<td >
<p>prs</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Pitcairn-Norfolk</p>
</td>
<td >
<p>pih</p>
</td>
<td >
<p>pih</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Polish</p>
</td>
<td >
<p>pl</p>
</td>
<td >
<p>pl</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Punjabi</p>
</td>
<td >
<p>pa</p>
</td>
<td >
<p>pa</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Romanian</p>
</td>
<td >
<p>ro</p>
</td>
<td >
<p>ro</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Rundi</p>
</td>
<td >
<p>rn</p>
</td>
<td >
<p>rn</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Russian</p>
</td>
<td >
<p>ru</p>
</td>
<td >
<p>ru</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Samoan</p>
</td>
<td >
<p>sm</p>
</td>
<td >
<p>sm</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Sanskrit</p>
</td>
<td >
<p>sa</p>
</td>
<td >
<p>sa</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Serbo-Croatian</p>
</td>
<td >
<p>sh</p>
</td>
<td >
<p>sh-Latn</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Croatian</p>
</td>
<td >
<p>hr</p>
</td>
<td >
<p>hr</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Montenegrin</p>
</td>
<td >
<p>cnr</p>
</td>
<td >
<p>cnr-Latn</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Serbian</p>
</td>
<td >
<p>sr</p>
</td>
<td >
<p>sr-Latn</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Seychellois Creole</p>
</td>
<td >
<p>crs</p>
</td>
<td >
<p>crs</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Sichuan Yi</p>
</td>
<td >
<p>ii</p>
</td>
<td >
<p>ii</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td colspan="2" >
<p>American Sign Language</p>
</td>
<td >
<p>ase</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td colspan="2" >
<p>Australian Sign Language</p>
</td>
<td >
<p>asf</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td colspan="2" >
<p>Brazilian Sign Language</p>
</td>
<td >
<p>bzs</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td colspan="2" >
<p>British Sign Language</p>
</td>
<td >
<p>sgn-bfi</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td colspan="2" >
<p>French Sign Language</p>
</td>
<td >
<p>fsl</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td colspan="2" >
<p>Indian Sign Language</p>
</td>
<td >
<p>ins</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td colspan="2" >
<p>Russian Sign Language</p>
</td>
<td >
<p>rsl</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Sindhi</p>
</td>
<td >
<p>sd</p>
</td>
<td >
<p>&nbsp;</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Sinhala</p>
</td>
<td >
<p>si</p>
</td>
<td >
<p>si</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Slovak</p>
</td>
<td >
<p>sk</p>
</td>
<td >
<p>sk</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Slovenian</p>
</td>
<td >
<p>sl</p>
</td>
<td >
<p>sl</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Somali</p>
</td>
<td >
<p>so</p>
</td>
<td >
<p>so</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Southern Sotho</p>
</td>
<td >
<p>st</p>
</td>
<td >
<p>st</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Spanish as spoken in Argentina</p>
</td>
<td >
<p>es-AR</p>
</td>
<td >
<p>es-AR</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Spanish as spoken in Bolivia</p>
</td>
<td >
<p>es-BO</p>
</td>
<td >
<p>es-BO</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Spanish as spoken in Colombia</p>
</td>
<td >
<p>es-COL</p>
</td>
<td >
<p>es-COL</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Spanish as spoken in Costa Rica</p>
</td>
<td >
<p>es-CR</p>
</td>
<td >
<p>es-CR</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Spanish as spoken in Cuba</p>
</td>
<td >
<p>es-CU</p>
</td>
<td >
<p>es-CU</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Spanish as spoken in Ecuador</p>
</td>
<td >
<p>es-EC</p>
</td>
<td >
<p>es-EC</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Spanish as spoken in El Salvador</p>
</td>
<td >
<p>es-SV</p>
</td>
<td >
<p>es-SV</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Spanish as spoken in Honduras</p>
</td>
<td >
<p>es-HN</p>
</td>
<td >
<p>es-HN</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Spanish as spoken in United States</p>
</td>
<td >
<p>es-US</p>
</td>
<td >
<p>es-US</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Spanish as spoken in Mexico</p>
</td>
<td >
<p>es-MX</p>
</td>
<td >
<p>es-MX</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Spanish as spoken in Nicaragua</p>
</td>
<td >
<p>es-NI</p>
</td>
<td >
<p>es-NI</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Spanish as spoken in Panama</p>
</td>
<td >
<p>es-PA</p>
</td>
<td >
<p>es-PA</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Spanish as spoken in Paraguay</p>
</td>
<td >
<p>es-PY</p>
</td>
<td >
<p>es-PY</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Spanish as spoken in Peru</p>
</td>
<td >
<p>es-PE</p>
</td>
<td >
<p>es-PE</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Spanish as spoken in Puerto Rico</p>
</td>
<td >
<p>es-PR</p>
</td>
<td >
<p>es-PR</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Spanish as spoken in Uruguay</p>
</td>
<td >
<p>es-UY</p>
</td>
<td >
<p>es-UY</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Spanish as spoken in Venezuela</p>
</td>
<td >
<p>es-VE</p>
</td>
<td >
<p>es-VE</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Sranan Tongo</p>
</td>
<td >
<p>srn</p>
</td>
<td >
<p>srn</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Swahili</p>
</td>
<td >
<p>swh</p>
</td>
<td >
<p>sw</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Swati</p>
</td>
<td >
<p>ss</p>
</td>
<td >
<p>ss</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Swedish</p>
</td>
<td >
<p>sv</p>
</td>
<td >
<p>sv</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Swiss German</p>
</td>
<td >
<p>gsw</p>
</td>
<td >
<p>gsw</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Tagalog</p>
</td>
<td >
<p>tl</p>
</td>
<td >
<p>tl</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Tahitian</p>
</td>
<td >
<p>ty</p>
</td>
<td >
<p>ty</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Tajik</p>
</td>
<td >
<p>tg</p>
</td>
<td >
<p>tg-Cyrl</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Tamil</p>
</td>
<td >
<p>ta</p>
</td>
<td >
<p>ta</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Telugu</p>
</td>
<td >
<p>te</p>
</td>
<td >
<p>te</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Thai</p>
</td>
<td >
<p>th</p>
</td>
<td >
<p>th</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Tibetan</p>
</td>
<td >
<p>bo</p>
</td>
<td >
<p>bo</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Tigrinya</p>
</td>
<td >
<p>ti</p>
</td>
<td >
<p>ti</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Tok Pisin</p>
</td>
<td >
<p>tpi</p>
</td>
<td >
<p>tpi</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Tongan (Tonga Islands)</p>
</td>
<td >
<p>to</p>
</td>
<td >
<p>to</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Tongan as spoken in Malawi</p>
</td>
<td >
<p>tog</p>
</td>
<td >
<p>tog</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Tongan as spoken in Zambia, Zimbabwe, and Mozambique</p>
</td>
<td >
<p>toi</p>
</td>
<td >
<p>toi</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Tsonga</p>
</td>
<td >
<p>ts</p>
</td>
<td >
<p>ts</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Tswana</p>
</td>
<td >
<p>tn</p>
</td>
<td >
<p>tn</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Tulu</p>
</td>
<td >
<p>tcy</p>
</td>
<td >
<p>tcy</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Turkish</p>
</td>
<td >
<p>tr</p>
</td>
<td >
<p>tr</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Turkmen</p>
</td>
<td >
<p>tk</p>
</td>
<td >
<p>tk</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Tuvaluan</p>
</td>
<td >
<p>tvl</p>
</td>
<td >
<p>tvl</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Ukrainian</p>
</td>
<td >
<p>uk</p>
</td>
<td >
<p>uk</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Urdu</p>
</td>
<td >
<p>ur</p>
</td>
<td >
<p>ur</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Uzbek</p>
</td>
<td >
<p>uz</p>
</td>
<td >
<p>uz</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Vietnamese</p>
</td>
<td >
<p>vi</p>
</td>
<td >
<p>vi</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Flemish</p>
</td>
<td >
<p>vls</p>
</td>
<td >
<p>vls</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Welsh</p>
</td>
<td >
<p>cy</p>
</td>
<td >
<p>cy</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Wolof</p>
</td>
<td >
<p>wo</p>
</td>
<td >
<p>wo</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Xhosa</p>
</td>
<td >
<p>xh</p>
</td>
<td >
<p>xh</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Yiddish</p>
</td>
<td >
<p>yi</p>
</td>
<td >
<p>yi</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
<tr>
<td >
<p>Yoruba</p>
</td>
<td >
<p>yo</p>
</td>
<td >
<p>yo</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>

<tr>
<td>
<p>Cantonese as spoken in mainland China</p>
</td>
<td>
<p>yue-CN</p>
</td>
<td>
<p>yue-CN</p>
</td>
<td>
<p>Deprecated</p>
</td>
</tr>

<tr>
<td >
<p>Zulu</p>
</td>
<td >
<p>zu</p>
</td>
<td >
<p>zu</p>
</td>
<td >
<p>Deprecated</p>
</td>
</tr>
</tbody>
</table>)