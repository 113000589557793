// import React from "react"
// import { styled } from "@emotion/styled"
import styled from "styled-components"

export const Footer = styled.footer`
  justify-content: center;
  color: var(--tw-white);
  background: var(--tw-black);
  padding-top: 5px;
  padding-bottom: 5px;
  left: 0px;
  width: 100%;
  font-size: 10px;
  grid-area: footer;
  margin-top: 20em;
  z-index: 1
  // position: fixed;
  bottom: 0;
  display: flex;
`
