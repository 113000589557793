import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { Menu } from "styled-icons/material/Menu"
import logo from "../images/wbd_horizontal_logo_black.png"
// import { Menu } from "styled-icons/boxicons-regular/Menu"

const MenuIcon = styled(Menu)`
  color: var(--tw-black);
`

const MenuDiv = styled.div`
  width: 24px;
  padding-right: 5px;
  padding-left: 10px;
`

//const wbdLogo = require("../images/wbd_horizontal_logo_black.png")

const Header = ({ siteTitle }) => (
  <header
    style={{
      gridArea: `header`,
      background: `var(--tw-white)`,
      position: "sticky",
      top: 0,
      width: "100vw",
      height: "3rem",
      boxShadow: "0 0 3px #555",
      display: "flex",
      alignItems: "center",
      zIndex: 100,
      overflow: "hidden",
      whiteSpace: "pre-wrap",
    }}
  >
    <div
      style={{
        display: `flex`,
        flex: `auto 1 1`,
        alignItems: "center",
      }}
    >
      <Link
        to="/"
        style={{
          color: `black`,
          textDecoration: `none`,
          display: "contents",
        }}
      >
        {/* <MenuDiv>
        <MenuIcon />
      </MenuDiv> */}
        <img
          src={logo}
          alt={"Warner Bros. Discovery logo"}
          style={{ width: "180px", marginLeft: "20px" }}
        />
        <span
          style={{
            fontSize: `16px`,
            marginLeft: "10px",
            overflow: "auto",
            whiteSpace: "pre-wrap",
          }}
        >
          |&nbsp;{siteTitle}
        </span>
      </Link>
      <a
        className="headerlink"
        href="mailto:WMContentAcquisitions@warnermedia.com"
      >
        Support
      </a>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
