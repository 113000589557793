import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"

const ListItem = styled(({ className, ...props }) => {
  return (
    <li className={className}>
      <a href={props.to} {...props} />
    </li>
  )
})`
  list-style: none;
  padding-top: 6px;

  a {
    color: var(--tw-max);
    font-weight: 500;
    text-decoration: none;
    font-size: 11px;

    &:hover {
      color: var(--tw-blue);
    }
  }
`

const RightSidebar = styled("aside")`
  // width: 18%;
  height: auto;
  border-left: solid 2px var(--tw-max);
  overflow: hidden;
  position: fixed;
  // margin-left: 79%;
  margin-top: 2%;
  padding-left: 0%;
  padding-right: 0%;
  padding-top: 0%;
  font-color: var(--tw-blue);

  & ul {
    margin: initial;
    padding-left: 2px;
    padding-right: 10px;
  }
`

const SidebarLayout = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        allMdx {
          edges {
            node {
              fields {
                slug
                title
              }
              tableOfContents
            }
          }
        }
      }
    `}
    render={({ allMdx }) => {
      let finalNavItems
      if (allMdx.edges !== undefined && allMdx.edges.length > 0) {
        allMdx.edges.map((item, index) => {
          let innerItems
          if (item !== undefined) {
            // get the TOC for this page
            if (item.node.fields.slug === location.pathname) {
              // console.log(item.node.tableOfContents.items)
              if (item.node.tableOfContents.items) {
                // Only showing the top level of the the TOC... can be changed later
                innerItems = item.node.tableOfContents.items.map(
                  (innerItem, index) => {
                    // generate link
                    const itemId = innerItem.title
                      ? innerItem.title.replace(/\s+/g, "").toLowerCase()
                      : "#"
                    return (
                      <ListItem key={index} to={`#${itemId}`} level={1}>
                        {innerItem.title}
                      </ListItem>
                    )
                  }
                )
              }
            }
          }
          if (innerItems) {
            finalNavItems = innerItems
          }
        })
      }

      // only show if there is more then one section
      if (finalNavItems && finalNavItems.length > 0) {
        return (
          <RightSidebar>
            <div>
              <a href="#title" className="rightsb">
                Contents
              </a>
            </div>
            <ul className={"sideBarUL"}>{finalNavItems}</ul>
          </RightSidebar>
        )
      } else {
        return (
          <RightSidebar>
            <ul></ul>
          </RightSidebar>
        )
      }
    }}
  />
)

export default SidebarLayout
