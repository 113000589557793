import React from "react"
import { detect } from "detect-browser"
import styled from "styled-components"

const browser = detect()

const UnsupportedBrowserPage = styled(
  ({ className }) =>
    browser &&
    browser.name &&
    browser.name !== "chrome" && (
      <div className={className}>
        {/* This site is best viewed with Google Chrome */}
      </div>
    )
)`
  // padding: 10px;
  // border-radius: 10px;
  // background-color: bisque;
  // font-size: medium;
  // display: flex;
  // margin: 10px;
`

export default UnsupportedBrowserPage
