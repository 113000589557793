/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Header from "./header"
import "./layout.css"
import Sidebar from "./sidebar"
import RightSidebar from "./sidebar/indexRight"
import { Footer } from "./footer"
import { MDXProvider } from "@mdx-js/react"
import mdxComponents from "./mdxComponents"

// alert("This site is undergoing scheduled maintenance from 12:00-14:00 PM EST. Sorry for the inconvenience.");

const Wrapper = styled("div")`
  display: grid;
  grid-template-areas:
    "header header header"
    "nav main right-nav"
    "footer footer footer";
  @media (max-width: 60rem) {
    grid-template-areas:
      " header"
      " main "
      " footer";
  }
  grid-template-columns: minmax(auto, 12em) 5fr;
  grid-template-rows: auto 1fr auto;
`

const Content = styled("main")`
@media (max-width: 100%) {

}
@media screen and (max-width: 50rem) {  {
      width: auto;
      position: relative;
      // font-size: 70%;
      display: flex;
      margin-left -0%;
  }
  
}
  grid-area: main;
  position: relative;
  border-radius: 4px;
  margin-top: 2%;
  margin-left: 1vw;
  // margin-right: 25%;
  margin-bottom: 4%;
  padding-top: 4%;
  padding-bottom: 4%;
  padding-left: 3%;
  padding-right: 3%;
  background-color: white;
  box-shadow: 0 0 2px #555;
  line-height: 120%;
  min-width: 400px;
  min-height: 460px;
`

const MaxWidth = styled("div")`
@media only screen and (max-width: 100rem) {
  width: 100%;

`
const LeftSideBarWidth = styled("div")`
  @media (max-width: 60rem) {
    display: none !important;
  }
  grid-area: nav;
  width: 270px;
`
const RightSideBarWidth = styled("div")`
  @media (max-width: 80rem) {
    display: none !important;
  }
  min-width: 200px;
  margin-left: 20px;
  grid-area: right-nav;
`

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <MDXProvider components={mdxComponents}>
      <Wrapper>
        <Header siteTitle={data.site.siteMetadata.title} />
        <LeftSideBarWidth>
          <Sidebar />
        </LeftSideBarWidth>
        <Content>
          <MaxWidth>{children}</MaxWidth>
        </Content>
        <RightSideBarWidth>
          <RightSidebar location={location} />
        </RightSideBarWidth>
        <Footer>
          <div>©&nbsp;2022 Warner Bros. Discovery, LLC. All Rights Reserved.</div>
        </Footer>
      </Wrapper>
    </MDXProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
