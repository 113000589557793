import React from "react"

export default () => (
<table>
  <thead>
      <tr>
         <th scope="col">Description</th>
         <th scope="col">Audio Language Tag</th>
         <th scope="col">Visual Language Tag</th>
         <th scope="col">Status</th>
      </tr>
   </thead>
<tbody>
<tr>
<td>
<p>Afrikaans as spoken in South Africa</p>
</td>
<td>
<p>af-ZA</p>
</td>
<td>
<p>af-ZA</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Amharic as spoken in Ethiopia</p>
</td>
<td>
<p>am-ET</p>
</td>
<td>
<p>am-ET</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Arabic as spoken in Saudi Arabia</p>
</td>
<td>
<p>ar-SA</p>
</td>
<td>
<p>ar-SA</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Azeri as spoken in Azerbaijan</p>
</td>
<td>
<p>az-AZ</p>
</td>
<td>
<p>az-AZ</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Belarusian as spoken in Belarus</p>
</td>
<td>
<p>be-BY</p>
</td>
<td>
<p>be-BY</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Bulgarian as spoken in Bulgaria</p>
</td>
<td>
<p>bg-BG</p>
</td>
<td>
<p>bg-BG</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Bislama as spoken in Vanuatu</p>
</td>
<td>
<p>bi-VU</p>
</td>
<td>
<p>bi-VU</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Bengali as spoken in Bangladesh</p>
</td>
<td>
<p>bn-BD</p>
</td>
<td>
<p>bn-BD</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Bengali as spoken in India</p>
</td>
<td>
<p>bn-IN</p>
</td>
<td>
<p>bn-IN</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Bosnian as spoken in Bosnia and Herzegovina</p>
</td>
<td>
<p>bs-BA</p>
</td>
<td>
<p>bs-BA</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Catalan as spoken in Spain</p>
</td>
<td>
<p>ca-ES</p>
</td>
<td>
<p>ca-ES</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>

<tr>
<td>
<p>Mandarin as spoken in Mainland China</p>
</td>
<td>
<p>cmn-CN</p>
</td>
<td>
<p>cmn-CN</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>

<tr>
<td>
<p>Mandarin as spoken in Singapore</p>
</td>
<td>
<p>cmn-SG</p>
</td>
<td>
<p></p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>

<tr>
<td>
<p>Mandarin as spoken in Taiwan</p>
</td>
<td>
<p>cmn-TW</p>
</td>
<td>
<p></p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>

<tr>
<td>
<p>Montenegrin as spoken in Montenegro</p>
</td>
<td>
<p>cnr-ME</p>
</td>
<td>
<p>cnr-ME</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>

<tr>
<td>
<p>Seselwa Creole French as spoken in the Seychelles</p>
</td>
<td>
<p>crs-SC</p>
</td>
<td>
<p>crs-SC</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Czech as spoken in the Czech Republic</p>
</td>
<td>
<p>cs-CZ</p>
</td>
<td>
<p>cs-CZ</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Welsh as spoken in the United Kingdom</p>
</td>
<td>
<p>cy-GB</p>
</td>
<td>
<p>cy-GB</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Danish as spoken in Denmark</p>
</td>
<td>
<p>da-DK</p>
</td>
<td>
<p>da-DK</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>German as spoken in Austria</p>
</td>
<td>
<p>de-AT</p>
</td>
<td>
<p>de-AT</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>German as spoken in Germany</p>
</td>
<td>
<p>de-DE</p>
</td>
<td>
<p>de-DE</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>German as spoken in Switzerland</p>
</td>
<td>
<p>de-CH</p>
</td>
<td>
<p>de-CH</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Divehi as spoken in the Maldives</p>
</td>
<td>
<p>dv-MV</p>
</td>
<td>
<p>dv-MV</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Dzongkha as spoken in Bhutan</p>
</td>
<td>
<p>dz-BT</p>
</td>
<td>
<p>dz-BT</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Greek (Modern, 1453-) as spoken in Greece</p>
</td>
<td>
<p>el-GR</p>
</td>
<td>
<p>el-GR</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>English as spoken in the United Kingdom</p>
</td>
<td>
<p>en-GB</p>
</td>
<td>
<p>en-GB</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>English as spoken in the United States</p>
</td>
<td>
<p>en-US</p>
</td>
<td>
<p>en-US</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Spanish as spoken in Latin America</p>
</td>
<td>
<p>es-419</p>
</td>
<td>
<p>es-419</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Spanish as spoken in Chile</p>
</td>
<td>
<p>es-CL</p>
</td>
<td>
<p>es-CL</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Castilian as spoken in Spain</p>
</td>
<td>
<p>es-ES</p>
</td>
<td>
<p>es-ES</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Estonian as spoken in Estonia</p>
</td>
<td>
<p>et-EE</p>
</td>
<td>
<p>et-EE</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Basque as spoken in Spain</p>
</td>
<td>
<p>eu-ES</p>
</td>
<td>
<p>eu-ES</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Farsi as spoken in Iran</p>
</td>
<td>
<p>fa-IR</p>
</td>
<td>
<p>fa-IR</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Finnish as spoken in Finland</p>
</td>
<td>
<p>fi-FI</p>
</td>
<td>
<p>fi-FI</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Filipino as spoken in the Philippines</p>
</td>
<td>
<p>fil-PH</p>
</td>
<td>
<p>fil-PH</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Fijian as spoken in Fiji</p>
</td>
<td>
<p>fj-FJ</p>
</td>
<td>
<p>fj-FJ</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Faroese as spoken in Denmark</p>
</td>
<td>
<p>fo-DK</p>
</td>
<td>
<p>fo-DK</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>French as spoken in Canada</p>
</td>
<td>
<p>fr-CA</p>
</td>
<td>
<p>fr-CA</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>French as spoken in France</p>
</td>
<td>
<p>fr-FR</p>
</td>
<td>
<p>fr-FR</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Gaelic as spoken in Ireland</p>
</td>
<td>
<p>ga-IE</p>
</td>
<td>
<p>ga-IE</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Galician as spoken in Spain</p>
</td>
<td>
<p>gl-ES</p>
</td>
<td>
<p>gl-ES</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Gujarati as spoken in India</p>
</td>
<td>
<p>gu-IN</p>
</td>
<td>
<p>gu-IN</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Hebrew as spoken in Isreal</p>
</td>
<td>
<p>he-IL</p>
</td>
<td>
<p>he-IL</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Hindi as spoken in India</p>
</td>
<td>
<p>hi-IN</p>
</td>
<td>
<p>hi-IN</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Fiji Hindi as spoken in Fiji</p>
</td>
<td>
<p>hif-FJ</p>
</td>
<td>
<p>hif-FJ</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Croatian as spoken in Croatia</p>
</td>
<td>
<p>hr-HR</p>
</td>
<td>
<p>hr-HR</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Hungarian as spoken in Hungary</p>
</td>
<td>
<p>hu-HU</p>
</td>
<td>
<p>hu-HU</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Hungarian as spoken in Romania</p>
</td>
<td>
<p>hu-RO</p>
</td>
<td>
<p>hu-RO</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Hungarian as spoken in Slovakia</p>
</td>
<td>
<p>hu-SK</p>
</td>
<td>
<p>hu-SK</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Armenian as spoken in Armenia</p>
</td>
<td>
<p>hy-AM</p>
</td>
<td>
<p>hy-AM</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Indonesian as spoken in Indonesia</p>
</td>
<td>
<p>id-ID</p>
</td>
<td>
<p>id-ID</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Icelandic as spoken in Iceland</p>
</td>
<td>
<p>is-IS</p>
</td>
<td>
<p>is-IS</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Italian as spoken in Italy</p>
</td>
<td>
<p>it-IT</p>
</td>
<td>
<p>it-IT</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>italian as spoken in Switzerland</p>
</td>
<td>
<p>it-CH</p>
</td>
<td>
<p>it-CH</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Japanese in Hiragana symbols</p>
</td>
<td>
<p>n/a</p>
</td>
<td>
<p>ja-Hira-JP</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Japanese as spoken in Japan</p>
</td>
<td>
<p>ja-JP</p>
</td>
<td>
<p>n/a</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Japanese in Han (Kanji), Hiragana, and Katakana symbols</p>
</td>
<td>
<p>n/a</p>
</td>
<td>
<p>ja-Jpan-JP</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Japanese in Katakana symbols</p>
</td>
<td>
<p>n/a</p>
</td>
<td>
<p>ja-Kata-JP</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Georgian as spoken in Georgia</p>
</td>
<td>
<p>ka-GE</p>
</td>
<td>
<p>ka-GE</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Kazakh as spoken in Kazakhstan</p>
</td>
<td>
<p>kk-KZ</p>
</td>
<td>
<p>kk-KZ</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Kalaallisut as spoken in Greenland</p>
</td>
<td>
<p>kl-GL</p>
</td>
<td>
<p>kl-GL</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Central Khmer as spoken in Cambodia</p>
</td>
<td>
<p>km-KH</p>
</td>
<td>
<p>km-KH</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>

<tr>
<td>
<p>Kannada as spoken in India</p>
</td>
<td>
<p>kn-IN</p>
</td>
<td>
<p>kn-IN</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>

<tr>
<td>
<p>Korean as spoken in South Korea</p>
</td>
<td>
<p>ko-KR</p>
</td>
<td>
<p>ko-KR</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Kirghiz as spoken in Kyrgyzstan</p>
</td>
<td>
<p>ky-KG</p>
</td>
<td>
<p>ky-KG</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Latin</p>
</td>
<td>
<p>la</p>
</td>
<td>
<p>la</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Luxembourgish as spoken in Luxembourg</p>
</td>
<td>
<p>lb-LU</p>
</td>
<td>
<p>lb-LU</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Ganda as spoken in Uganda</p>
</td>
<td>
<p>lg-UG</p>
</td>
<td>
<p>lg-UG</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Lao as spoken in Laos</p>
</td>
<td>
<p>lo-LA</p>
</td>
<td>
<p>lo-LA</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Lithuanian as spoken in Lithuania</p>
</td>
<td>
<p>lt-LT</p>
</td>
<td>
<p>lt-LT</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Latvian as spoken in Latvia</p>
</td>
<td>
<p>lv-LV</p>
</td>
<td>
<p>lv-LV</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Motu as spoken in Papua New Guinea</p>
</td>
<td>
<p>meu-PG</p>
</td>
<td>
<p>meu-PG</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Malagasy as spoken in Madagascar</p>
</td>
<td>
<p>mg-MG</p>
</td>
<td>
<p>mg-MG</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Macedonian as spoken in Macedonia</p>
</td>
<td>
<p>mk-MK</p>
</td>
<td>
<p>mk-MK</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Malayalam as spoken in India</p>
</td>
<td>
<p>ml-IN</p>
</td>
<td>
<p>ml-IN</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Mongolian as spoken in Mongolia</p>
</td>
<td>
<p>mn-MN</p>
</td>
<td>
<p>mn-MN</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Marathi as spoken in India</p>
</td>
<td>
<p>mr-IN</p>
</td>
<td>
<p>mr-IN</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>

<tr>
<td>
<p>Malay as spoken in Malaysia</p>
</td>
<td>
<p>ms-MY</p>
</td>
<td>
<p>ms-MY</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>

<tr>
<td>
<p>Maltese as spoken in Malta</p>
</td>
<td>
<p>mt-MT</p>
</td>
<td>
<p>mt-MT</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Burmese as spoken in Myanmar</p>
</td>
<td>
<p>my-MM</p>
</td>
<td>
<p>my-MM</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Nauru as spoken in Nauru</p>
</td>
<td>
<p>na-NR</p>
</td>
<td>
<p>na-NR</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Min Nan as spoken in Taiwan</p>
</td>
<td>
<p>nan-TW</p>
</td>
<td>
<p>nan-TW</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Neapolitan as spoken in Italy</p>
</td>
<td>
<p>nap-IT</p>
</td>
<td>
<p>nap-IT</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Nepali as spoken in Nepal</p>
</td>
<td>
<p>ne-NP</p>
</td>
<td>
<p>ne-NP</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Dutch as spoken in Belgium (Flemish)</p>
</td>
<td>
<p>nl-BE</p>
</td>
<td>
<p>nl-BE</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Dutch as spoken in The Netherlands</p>
</td>
<td>
<p>nl-NL</p>
</td>
<td>
<p>nl-NL</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Norwegian as spoken in Norway</p>
</td>
<td>
<p>no-NO</p>
</td>
<td>
<p>no-NO</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Chewa as spoken in Malawi</p>
</td>
<td>
<p>ny-MW</p>
</td>
<td>
<p>ny-MW</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Panjabi as spoken in India</p>
</td>
<td>
<p>pa-IN</p>
</td>
<td>
<p>pa-IN</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Punjabi as spoken in Pakistan</p>
</td>
<td>
<p>pa-PK</p>
</td>
<td>
<p>pa-PK</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Palauan as spoken in Palau</p>
</td>
<td>
<p>pau-PW</p>
</td>
<td>
<p>pau-PW</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Pitcairn-Norfolk as spoken in Pitcairn</p>
</td>
<td>
<p>pih-PN</p>
</td>
<td>
<p>pih-PN</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Polish as spoken in Poland</p>
</td>
<td>
<p>pl-PL</p>
</td>
<td>
<p>pl-PL</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Dari as spoken in Afghanistan</p>
</td>
<td>
<p>prs-AF</p>
</td>
<td>
<p>prs-AF</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Pashto as spoken in Afghanistan</p>
</td>
<td>
<p>ps-AF</p>
</td>
<td>
<p>ps-AF</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Portuguese as spoken in Brazil</p>
</td>
<td>
<p>pt-BR</p>
</td>
<td>
<p>pt-BR</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Portuguese as spoken in Portugal</p>
</td>
<td>
<p>pt-PT</p>
</td>
<td>
<p>pt-PT</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Rundi as spoken in Burundi</p>
</td>
<td>
<p>rn-BI</p>
</td>
<td>
<p>rn-BI</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Romanian as spoken in Moldova</p>
</td>
<td>
<p>ro-MD</p>
</td>
<td>
<p>ro-MD</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Romanian as spoken in Romania</p>
</td>
<td>
<p>ro-RO</p>
</td>
<td>
<p>ro-RO</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Russian as spoken in Russia</p>
</td>
<td>
<p>ru-RU</p>
</td>
<td>
<p>ru-RU</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Kinyarwanda as spoken in Rwanda</p>
</td>
<td>
<p>rw-RW</p>
</td>
<td>
<p>rw-RW</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Sinhala as spoken in Sri Lanka</p>
</td>
<td>
<p>si-LK</p>
</td>
<td>
<p>si-LK</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Slovakian as spoken in Slovakia</p>
</td>
<td>
<p>sk-SK</p>
</td>
<td>
<p>sk-SK</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Slovenian as spoken in Slovenia</p>
</td>
<td>
<p>sl-SI</p>
</td>
<td>
<p>sl-SI</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Samoan as spoken in Samoa</p>
</td>
<td>
<p>sm-WS</p>
</td>
<td>
<p>sm-WS</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Somali as spoken in Somalia</p>
</td>
<td>
<p>so-SO</p>
</td>
<td>
<p>so-SO</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Albanian as spoken in Albania</p>
</td>
<td>
<p>sq-AL</p>
</td>
<td>
<p>sq-AL</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Serbian Latin</p>
</td>
<td>
<p>sr-RS</p>
</td>
<td>
<p>sr-Latn-RS</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Sranan Tongo as spoken in Suriname</p>
</td>
<td>
<p>srn-SR</p>
</td>
<td>
<p>srn-SR</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Swati as spoken in eSwatini</p>
</td>
<td>
<p>ss-SZ</p>
</td>
<td>
<p>ss-SZ</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Southern Sotho as spoken in South Africa</p>
</td>
<td>
<p>st-ZA</p>
</td>
<td>
<p>st-ZA</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Swedish as spoken in Sweden</p>
</td>
<td>
<p>sv-SE</p>
</td>
<td>
<p>sv-SE</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Swahili as spoken in Kenya</p>
</td>
<td>
<p>swh-KE</p>
</td>
<td>
<p>swh-KE</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Swahili as spoken in Tanzania</p>
</td>
<td>
<p>swh-TZ</p>
</td>
<td>
<p>swh-TZ</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Tamil as spoken in India</p>
</td>
<td>
<p>ta-IN</p>
</td>
<td>
<p>ta-IN</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>

<tr>
<td>
<p>Telugu as spoken in India</p>
</td>
<td>
<p>te-IN</p>
</td>
<td>
<p>te-IN</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>

<tr>
<td>
<p>Tajik as spoken in Tajikistan</p>
</td>
<td>
<p>tg-TJ</p>
</td>
<td>
<p>tg-TJ</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Thai as spoken in Thailand</p>
</td>
<td>
<p>th-TH</p>
</td>
<td>
<p>th-TH</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Tigrinya as spoken in Eritrea</p>
</td>
<td>
<p>ti-ER</p>
</td>
<td>
<p>ti-ER</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Turkmen as spoken in Turkmenistan</p>
</td>
<td>
<p>tk-TM</p>
</td>
<td>
<p>tk-TM</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Tagalog as spoken in Philippines</p>
</td>
<td>
<p>tl-PH</p>
</td>
<td>
<p>tl-PH</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Tswana as spoken in Botswana</p>
</td>
<td>
<p>tn-BW</p>
</td>
<td>
<p>tn-BW</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Tongan as spoken in the Tongan Islands</p>
</td>
<td>
<p>to-TO</p>
</td>
<td>
<p>to-TO</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Tok Pisin as spoken in Papua New Guinea</p>
</td>
<td>
<p>tpi-PG</p>
</td>
<td>
<p>tpi-PG</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Turkish as spoken in Turkey</p>
</td>
<td>
<p>tr-TR</p>
</td>
<td>
<p>tr-TR</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Tsonga as spoken in South Africa</p>
</td>
<td>
<p>ts-ZA</p>
</td>
<td>
<p>ts-ZA</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Tuvaluan as spoken in Tuvalu</p>
</td>
<td>
<p>tvl-TV</p>
</td>
<td>
<p>tvl-TV</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Tahitian as spoken in French Polynesia</p>
</td>
<td>
<p>ty-PF</p>
</td>
<td>
<p>ty-PF</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Ukranian as spoken in the Ukraine</p>
</td>
<td>
<p>uk-UA</p>
</td>
<td>
<p>uk-UA</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Undetermined</p>
</td>
<td>
<p>und</p>
</td>
<td>
<p>und</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Urdu as spoken in India</p>
</td>
<td>
<p>ur-IN</p>
</td>
<td>
<p>ur-IN</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Uzbek as spoken in Uzbekistan</p>
</td>
<td>
<p>uz-UZ</p>
</td>
<td>
<p>uz-UZ</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Vietnamese as spoken in Vietnam</p>
</td>
<td>
<p>vi-VN</p>
</td>
<td>
<p>vi-VN</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>

<tr>
<td>
<p>Cantonese as spoken in Hong Kong</p>
</td>
<td>
<p>yue-HK</p>
</td>
<td>
<p></p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>

<tr>
<td>
<p>Chinese</p>
</td>
<td>
<p>zh</p>
</td>
<td>
<p>zh</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>

<tr>
<td>
<p>Chinese Simplified based on Mandarin as spoken in Mainland China</p>
</td>
<td>
<p></p>
</td>
<td>
<p>zh-Hans-CN</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>

<tr>
<td>
<p>Chinese Simplified Singapore based on Mandarin as spoken in Taiwan</p>
</td>
<td>
<p></p>
</td>
<td>
<p>zh-Hans-TW</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>

<tr>
<td>
<p>Chinese Traditional Hong Kong as appropriate for Hong Kong</p>
</td>
<td>
<p></p>
</td>
<td>
<p>zh-Hant-HK</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>

<tr>
<td>
<p>Chinese Traditional as appropriate for Taiwan</p>
</td>
<td>
<p></p>
</td>
<td>
<p>zh-Hant-TW</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>

<tr>
<td>
<p>Malay as spoken in Singapore</p>
</td>
<td>
<p>zlm-SG</p>
</td>
<td>
<p>zlm-SG</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>Zulu as spoken in South Africa</p>
</td>
<td>
<p>zu-ZA</p>
</td>
<td>
<p>zu-ZA</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
<tr>
<td>
<p>No linguistic content</p>
</td>
<td>
<p>zxx</p>
</td>
<td>
<p>zxx</p>
</td>
<td>
<p>Accepted</p>
</td>
</tr>
</tbody>
</table>)