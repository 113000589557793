import React, { useState } from "react"
import TreeNode from "./treeNode"
import _ from "lodash"

// REQUIRED: this must be the same as sections
const forcedNavOrder = {

  items: [
    { url: "/" },
    {
      url: "/getting-started",
      items: [
        { url: "/getting-started/content-portal" },
        { url: "/getting-started/automated-content-delivery" },
      ],
    },
    { 
      url: "/specifications-and-guides",
      items: [
        { url: "/specifications-and-guides/licensed" },
        { url: "/specifications-and-guides/originals" },
        { url: "/specifications-and-guides/component-delivery" }, 
        { url: "/specifications-and-guides/promotionals" },
      ] 
    },
    { 
      url: "/metadata",
      items: [
        { url: "/metadata/automated-content-delivery" },
        { url: "/metadata/countries" },
        { url: "/metadata/language-tags" },
        { url: "/metadata/territories" },
        { url: "/metadata/metadata-template" },
      ]
    },
    { 
      url: "/image-specs",
      // items: [
      //   { url: "/image-specs/images" },
      //   { url: "/image-specs/languages"},
      // ] 
    },
  ],
}

function makeTreeOfEdges(edges) {
  const tree = edges.reduce(
    (
      accu,
      {
        node: {
          fields: { slug, title },
        },
      }
    ) => {
      const parts = _.compact(slug.split("/"))
      let { items: currentLevelItems } = accu

      if (parts.length === 0) {
        currentLevelItems.push({ title, url: slug, items: [] })
      }

      // build tree
      _.each(parts, (part, idx, coll) => {
        let tmp = currentLevelItems.find(({ label }) => label === part)
        // have it already?
        if (tmp) {
          // update values
          if (!tmp.items) {
            tmp.items = []
          }
        } else {
          tmp = { label: part, items: [] }
          currentLevelItems.push(tmp)
        }
        // is end of parts?
        if (idx === coll.length - 1) {
          // add url
          tmp.url = slug
          tmp.title = title
          tmp.label = part
        }
        // go into deeper level
        currentLevelItems = tmp.items
      })
      return accu
    },
    { items: [] }
  )
  return tree
}

function pullItems(fromValues, toOrder) {
  // for each item
  return _.map(toOrder, (val, idx, coll) => {
    // get this item fromValues
    const item = fromValues.find(({ url }) => url === val.url) //?
    if (item) {
      // recursive down
      if (_.isArray(val.items) && _.isArray(item.items)) {
        val.items = pullItems(item.items, val.items)
      } else {
        // sort leaf items alphabetically
        val.items = _.sortBy(item.items, ["title"])
      }
      val.label = item.label
      val.title = item.title
    }
    // get from valuesItems
    return val
  })
}

const Tree = ({ edges }) => {
  // build a tree for left-nav
  const [treeData] = useState(() => {
    const tree = makeTreeOfEdges(edges)
    return { items: pullItems(tree.items, forcedNavOrder.items) }
  })
  // const tree = makeTreeOfEdges(edges)
  // const treeData = { items: pullItems(tree.items, forcedNavOrder.items) }

  const [collapsed, setCollapsed] = useState({})
  const toggle = url => {
    setCollapsed({
      ...collapsed,
      [url]: !collapsed[url],
    })
  }
  return (
    <TreeNode
      className={"showFrontLine"}
      setCollapsed={toggle}
      collapsed={collapsed}
      {...treeData}
    />
  )
}

export default Tree
