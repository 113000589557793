import React from "react"

export default () => (

<table>
<thead>
      <tr>
         <th scope="col">Description</th>
         <th scope="col">Audio Language Tag</th>
         <th scope="col">Visual Language Tag</th>
         <th scope="col">Status</th>
      </tr>
   </thead>
  <tbody>
    <tr>
      <th>English</th>
      
      <td>en</td>
      <td>en</td>
      <td>Excluded</td>
    </tr>
    <tr>
      <th>Global English</th>
      
      <td>en-001</td>
      <td>en-001</td>
      <td>Excluded</td>
    </tr>
    <tr>
      <th>French</th>
      
      <td>fr</td>
      <td>fr</td>
      <td>Excluded</td>
    </tr>
    <tr>
      <th>Global French</th>
      
      <td>fr-001</td>
      <td>fr-001</td>
      <td>Excluded</td>
    </tr>
    <tr>
      <th>Spanish</th>
      
      <td>es</td>
      <td>es</td>
      <td>Excluded</td>
    </tr>
    <tr>
      <th>Global Spanish</th>
      
      <td>es-001</td>
      <td>es-001</td>
      <td>Excluded</td>
    </tr>
    <tr>
      <th>Portuguese</th>
      
      <td>pt</td>
      <td>pt</td>
      <td>Excluded</td>
    </tr>
    <tr>
      <th>German</th>
      
      <td>de</td>
      <td>de</td>
      <td>Excluded</td>
    </tr>
  </tbody>
</table>
)