import React from "react"
import OpenedSvg from "../../images/opened"
import ClosedSvg from "../../images/closed"
import { Link } from "gatsby"

const TreeNode = ({
  className = "",
  collapsed,
  setCollapsed,
  url,
  title,
  items = [],
  ...rest
}) => {
  const isCollapsed = collapsed[url]
  const collapse = () => {
    setCollapsed(url)
  }
  const hasChildren = items.length !== 0
  let location
  if (typeof document != "undefined") {
    location = document.location
  }
  const active = location && location.pathname === url

  const calculatedClassName = `${className} item ${active ? "active" : ""}`
  return (
    <li className={calculatedClassName}>
      {title && hasChildren ? (
        // Is the action when clicking on SVG collapsr
        <button onClick={collapse} className="collapser">
          {!isCollapsed ? <OpenedSvg /> : <ClosedSvg />}
        </button>
      ) : null}

      {title && <Link to={url}>{title}</Link>}

      {!isCollapsed && hasChildren ? (
        <ul>
          {items.map(item => (
            <TreeNode
              key={item.url}
              setCollapsed={setCollapsed}
              collapsed={collapsed}
              {...item}
            />
          ))}
        </ul>
      ) : null}
    </li>
  )
}
export default TreeNode
