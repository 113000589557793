import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import UnsupportedBrowserPage from "../components/unsupported-browser"

export default function Template(props) {
  const { data } = props
  const { mdx } = data
  const { frontmatter } = mdx
  return (
    <Layout {...props}>
      <SEO title={frontmatter.title} />

      <UnsupportedBrowserPage></UnsupportedBrowserPage>
      <h1>{frontmatter.title}</h1>
      <MDXRenderer>{mdx.body}</MDXRenderer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    mdx(fields: { id: { eq: $id } }) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
      }
    }
  }
`
